import { VUE_APP_WS_URL } from "@utils";

const Socket = function(uid,username,headimgurl,token) {
  this.ws = new WebSocket(VUE_APP_WS_URL + '?uid=' + uid + '&usertype=consumer&username=' + username + '&headimgurl=' + headimgurl + '&token=' + token);
  this.ws.onopen = this.onOpen.bind(this);
  this.ws.onerror = this.onError.bind(this);
  this.ws.onmessage = this.onMessage.bind(this);
  this.ws.onclose = this.onClose.bind(this);
};

Socket.prototype = {
  vm(vm) {
    this.vm = vm;
  },
  close() {
    clearInterval(this.timer);
    this.ws.close();
  },
  onOpen: function() {
    console.log("ws open");
    this.init();

  },
  init: function() {
    var that = this;
    this.timer = setInterval(function() {
      that.send({ type: "ping" });
    }, 10000);
  },
  send: function(data) {
    return this.ws.send(JSON.stringify(data));
  },
  onMessage: function(res) {
    const data = JSON.parse(res.data);
    this.vm.$emit('onmessage', data);
  },
  onClose: function() {
    clearInterval(this.timer);
  },
  onError: function(e) {
    console.log(e);
    this.vm.$emit("websocket_error", e);
  }
};

Socket.prototype.constructor = Socket;

export default Socket;
